@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;500;600;700;800&display=swap');

.noto-sans {
  font-family: "Noto Sans JP", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.shippori-mincho {
  font-family: "Shippori Mincho", serif;
  font-style: normal;
}

.josefin-slab {
  font-family: "Josefin Slab", serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.poppins {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

$en_font01: "Poppins", sans-serif;

/*ディスプレイ初期値設定*/
$bp_sp_ipse: 320px;
$bp_sp: 767px;
$bp_tb: 1024px;
$bp_pc_s: 1280px;
$bp_pc_m: 1366px;
$bp_pc_l: 1600px;
$bp_pc_ll: 1920px;

/*以下、サイト固有の設定*/

//カスタムフィールド設置するまでの仮
$main_color: #3c06a0;

$accent_color: #FFF8FB;

$sub_color01: #222222;
//$sub_color02: #01786E;
//$sub_color03: #FF8F00;

//$blue_color01: #02214D;
//$red_color01: #DC000C;

//$white_color: #fff;

$text_color01: #222;
$text_color02: #333;
$text_gray01: #bbb;
$text_gray02: #616161;

$pc-font_size: 16px;
$sp-font_size: 13.5px;

$content_width: 1200px;

$radius_size1: 10px;
// $radius_size2: 5px;

/*サイト共通の間隔*/
$pc_sp1: 100px;
$pc_sp2: 90px;
$pc_sp3: 60px;
$pc_sp4: 40px;
$pc_sp5: 30px;
$pc_sp6: 25px;
$pc_sp7: 20px;

$tb_sp1: 80px;
$tb_sp2: 60px;
$tb_sp3: 40px;
$tb_sp4: 30px;
$tb_sp5: 25px;
$tb_sp6: 20px;
$tb_sp7: 15px;

$sp_sp1: 16.1vw; //横幅375pxの時の60px
$sp_sp2: 16vw; //横幅375pxの時の60px
$sp_sp3: 8vw; //横幅375pxの時の30px
$sp_sp4: 5.33vw; //横幅375pxの時の20px
$sp_sp5: 4.27vw; //横幅375pxの時の16px
$sp_sp6: 4vw; //横幅375pxの時の15px
$sp_sp7: 2.67vw; //横幅375pxの時の10px

$basic_space_pc: $pc_sp1, $pc_sp2, $pc_sp3, $pc_sp4, $pc_sp5, $pc_sp6, $pc_sp7;
$basic_space_tb: $tb_sp1, $tb_sp2, $tb_sp3, $tb_sp4, $tb_sp5, $tb_sp6, $tb_sp7;
$basic_space_sp: $sp_sp1, $sp_sp2, $sp_sp3, $sp_sp4, $sp_sp5, $sp_sp6, $sp_sp7;

$content_space_pc: $pc_sp1;
$content_space_tb: $tb_sp1;
$content_space_sp: $sp_sp1;

$header_height_pc: 100px;
$header_height_sp: 60px;
$gnav_height_sp: 60px;

$basic_transition: 0.3s ease-out;
$hv_common_transition: 0.6s cubic-bezier(0.19, 1, 0.22, 1);
$hv_fill_transition: 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);

/*英語用font読み込み指定*/
/*
@font-face {
	font-family: 'General Sans';
	src: url("../font/GeneralSans-Regular.otf") format("otf");
    font-weight: normal;
    font-style: normal;
}
@font-face {
	font-family: 'General Sans';
	src: url("../font/GeneralSans-Semibold.otf") format("otf");
    font-weight: bold;
    font-style: normal;
}
*/

// $gothic: "M PLUS 1", "游ゴシック体", "Yu Gothic", YuGothic,
//   "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo,
//   "ＭＳ Ｐゴシック", sans-serif;
$gothic: YakuHanJP_Noto, "Noto Sans JP", "游ゴシック体", "Yu Gothic", YuGothic,
  "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo,
  "ＭＳ Ｐゴシック", sans-serif;
//$gothic: YakuHanJP, "游ゴシック体", "Yu Gothic", YuGothic,'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
$mincho: YakuHanMP, "游明朝", "Yu Mincho", "YuMincho", "メイリオ", Meiryo, serif;
//$eng_font: 'General Sans', sans-serif;
$eng_font: "Poppins", sans-serif;

@mixin max_scr($break_point) {
  @media screen and (max-width: $break_point) {
    @content;
  }
}

@mixin min_scr($break_point) {
  @media screen and (min-width: ($break_point + 1)) {
    @content;
  }
}

@mixin min_scr_pri($break_point) {

  @media print,
  screen and (min-width: $break_point) {
    @content;
  }
}

@mixin min_max_scr($break_point_min, $break_point_max) {
  @media screen and (min-width: ($break_point_min + 1)) and (max-width: $break_point_max) {
    @content;
  }
}