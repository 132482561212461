@charset "utf-8";
@import "_variables.scss";
/*
Theme Name: template1.01
Description: template1.01　全ページカスタマイズテーマです。
*/

/******************************/
/* 見出し　 */
/******************************/
.ttl00 {
  font-size: 40px;
  font-family: "Josefin Slab", serif;
  font-weight: 600;
  position: relative;
  line-height: 1.2;

  @include max_scr($bp_sp) {
    font-size: 30px;
  }

  &.type01 {
    text-align: left;

    @include max_scr($bp_tb) {
      text-align: center;
    }

    &:after {
      content: "／";
      display: block;
      font-size: 24px;
      margin-top: 5px;
    }
  }

  &.type02 {
    text-align: center;

    &:after {
      content: "／";
      display: block;
      font-size: 24px;
      margin-top: 5px;
    }
  }


}

.ttl01 {
  position: relative;
  margin-bottom: 60px;

  @include max_scr($bp_sp) {
    text-align: center;
  }

  &.non_line {
    &:after {
      display: none;
    }
  }

  &.type01 {
    .jp_txt {
      font-size: 16px;
      margin-top: 5px;
    }

    .eng_txt {
      font-family: "Josefin Slab", serif;
      font-weight: 600;
      font-size: 36px;
      display: inline-block;
      line-height: 1;

      @include max_scr($bp_sp) {
        font-size: 26px;
      }
    }
  }

  &.type02 {
    .jp_txt {
      font-size: 40px;
      line-height: 1;
    }

    .eng_txt {
      font-family: $en_font01;
      font-weight: bold;
      font-size: 18px;
      display: inline-block;
      color: $main-color;
      margin-bottom: 5px;

      @include max_scr($bp_sp) {
        font-size: 16px;
      }
    }

    &:after {
      bottom: -30px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -20px;
    display: inline-block;
    width: 100%;
    max-width: 40px;
    height: 1px;
    left: 0;
    border-bottom: solid 2px $main_color;

    @include max_scr($bp_sp) {
      content: "";
      position: absolute;
      bottom: -15px;
      display: inline-block;
      width: 100%;
      max-width: 40px;
      height: 1px;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: solid 2px $main_color;
    }
  }
}

.ttl02 {
  position: relative;
  margin-bottom: 60px;
  text-align: center;

  @include max_scr($bp_sp) {
    margin-bottom: 40px;
  }

  &.non_line {
    &:after {
      display: none;
    }
  }

  &.type01 {
    .jp_txt {
      font-size: 16px;
      margin-top: 5px;
    }

    .eng_txt {
      font-family: "Josefin Slab", serif;
      font-weight: 600;
      font-size: 50px;
      display: inline-block;
      line-height: 1;
      color: #9DB6C9;

      @include max_scr($bp_sp) {
        font-size: 24px;
      }
    }
  }

  &.type02 {
    .jp_txt {
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
    }

    .eng_txt {
      font-family: $en_font01;
      font-weight: 400;
      font-size: 34px;
      display: inline-block;
      margin-bottom: 5px;
      color: $main-color;

      @include max_scr($bp_sp) {
        font-size: 24px;
      }
    }

    &:after {
      bottom: -20px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -20px;
    display: inline-block;
    width: 100%;
    max-width: 40px;
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: solid 2px $main_color;
  }
}

.ttl03 {
  font-size: 22px;
  font-weight: bold;
  position: relative;
  border-left: solid 4px $main-color;
  margin-bottom: 20px;
  padding: 0 10px 0;

  @include max_scr($bp_sp) {
    font-size: 16px;
    margin-bottom: 15px;
  }

  &::before {
    content: "";
    position: absolute;
    width: calc(100%);
    left: -4px;
    bottom: -10px;
    height: 2px;
    border-bottom: dotted 2px $main-color;
  }

  &.non_border {
    &:before {
      display: none;
    }
  }
}

.ttl04 {
  position: relative;
  text-align: center;
  border-bottom: solid 1px #9DB6C9;
  padding-bottom: 5px;
  margin-bottom: 20px;

  @include max_scr($bp_sp) {}

  .jp_ttl {
    font-size: 15px;
    font-weight: 400;
    display: inline-block;
    margin-bottom: 3px;
  }

  .jp_ttl02 {
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
  }

  .eng_ttl {
    font-family: "Josefin Slab", serif;
    font-weight: 600;
    color: #75C1D1;
    font-size: 24px;
  }

}

.ttl05 {
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 22px;
  border-bottom: solid 1px #9DB6C9;
  padding-bottom: 12px;
  margin-bottom: 15px;
}

.ttl06 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0 16px;

  .en_ttl {
    font-family: "Josefin Slab", serif;
    color: #0D3761;
    font-weight: 600;
    font-size: 22px;
  }

  .jp_ttl {
    font-weight: 400;
    font-size: 16px;

    @include max_scr($bp_tb) {
      font-size: 13px;
    }
  }
}

.ttl07 {
  position: relative;

  &.en_txt {
    font-family: "Josefin Slab", serif;
    font-size: 22px;
    font-weight: 600;
    color: #218EB1;
    border-bottom: solid 1px #9DB6C9;
    padding-bottom: 5px;
    //margin-bottom: 5px;
  }
}

.ttl08 {
  position: relative;
  margin-bottom: 40px;
  font-weight: bold;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    height: 2px;
    width: 100px;
    background-color: $main_color;
  }
}

.ttl09 {
  position: relative;

  .sub_ttl {
    display: inline-block;
    box-sizing: border-box;
    border-radius: 100px;
    color: #fff;
    line-height: 1;
    font-size: 14px;
    padding: 5px 15px;
    margin-bottom: 5px;
    background-color: #75C1D1;

    &.cl02 {
      background-color: #8EA19E;
    }
  }

  .main_ttl {
    font-weight: 500;
    font-size: 20px;
    @include max_scr($bp_tb) {
    font-size: 16px;
    }
  }
}

.ttl10 {
  border-left: 4px solid #75C1D1;
  padding-left: 10px;
  font-weight: 500;
  line-height: 1.2;
}

.ttl11 {
  color: #0D3761;
  margin-bottom: 20px;

  .en_ttl {
    font-family: "Josefin Slab", serif;
    font-size: 24px;
    font-weight: 600;

    @include max_scr($bp_sp) {
      font-size: 18px;
    }

    &:after {
      content: "|";
      display: inline-block;
      padding-left: 10px;
    }
  }

  .jp_ttl {
    font-family: "Shippori Mincho", serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;

    @include max_scr($bp_sp) {
      font-size: 16px;
    }
  }
}

/******************************/
/* ボタン */
/******************************/
.btn01 {
  @include max_scr($bp_tb) {
    text-align: center;
  }

  a {
    font-family: "Josefin Slab", serif;
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 280px;
    background-color: #fff;
    border: solid 2px #75C1D1;
    color: #1D1D1D;
    border-radius: 100px;
    font-weight: 600;
    font-size: 16px;
    padding: 14px 35px;
    text-align: center;
    z-index: 1;
    overflow: hidden;
    box-sizing: border-box;

    @include max_scr($bp_tb) {
      font-size: 13px;
      max-width: 220px;
      line-height: 1;
    }

    &:hover {
      opacity: 1;
      color: #fff;

      &:before {
        transform: scale(1, 1);
      }
    }

    &:before {
      background: #75C1D1;
      color: #1D1D1D;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      transform: scale(0, 1);
      transform-origin: left top;
      transition: 0.2s cubic-bezier(0.45, 0, 0.55, 1);
      z-index: -1;
    }
  }
}

.btn02 {
  a {
    display: inline-block;
    width: 200px;
    font-family: $en_font01;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0em;
    line-height: 20px;
    border-bottom: 1px solid #1a1515;
    text-align: left;
    padding-bottom: 7px;
    margin: 26px 0;
    position: relative;
    box-sizing: border-box;

    &:before {
      content: "";
      position: absolute;
      z-index: 0;
      left: 0;
      right: 100%;
      bottom: 0;
      background: #1a1515;
      height: 1px;
      transition-property: right;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
    }

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      border: 1px solid #1a1515;
      /* font-family: 'Zen Old Mincho', serif; */
      font-weight: 600;
      font-size: 21px;
      box-sizing: border-box;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      margin-left: -15px;
      transition: all 0.3s;

      &.txt2 {
        font-family: $en_font01;
        font-size: 16px;
      }
    }

    &:hover {
      opacity: 0.7;

      &:before {
        right: 0;
      }

      i {
        background-color: #1a1515;
        color: #fff;
      }
    }
  }
}

.btn03 {
  a {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 280px;
    background-color: #ea6882;
    //border: solid 2px #E4007F;
    overflow: hidden;
    color: #fff;
    border-radius: 100px;
    font-weight: 500;
    font-size: 15px;
    padding: 14px 35px;
    text-align: left;
    z-index: 1;
    box-sizing: border-box;

    &:before {
      background: #be546a;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      transform: scale(0, 1);
      transform-origin: left top;
      transition: 0.2s cubic-bezier(0.45, 0, 0.55, 1);
      z-index: -1;
    }

    &:after {
      content: "\f061";
      font-style: normal;
      line-height: 1;
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      font-size: 18px;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      transition: all 0.3s;
      color: #333;
    }

    &:hover {
      color: #fff;

      &:before {
        transform: scale(1, 1);
      }

      &:after {
        right: 8px;
        color: #fff;
      }
    }
  }
}

.btn04 {
  a {
    display: inline-block;
    width: 100%;
    max-width: 280px;
    background-color: #ffa500;
    color: #333;
    border-radius: 6px;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0em;
    line-height: 24px;
    text-align: center;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 14px 0;
    box-shadow: 0 6px 0 #5f3f25;
    position: relative;
    z-index: 1;
    text-decoration: none;
    margin: 0 auto;
    box-sizing: border-box;

    &:after {
      content: "\f0a9";
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: var(--fa-display, inline-block);
      font-style: normal;
      font-variant: normal;
      line-height: 1;
      text-rendering: auto;
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      font-size: 18px;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      transition: all 0.3s;
      color: #333;
      background-color: #ffa500;
      border-radius: 50%;
    }
  }

  &:hover {
    opacity: 1;
    transform: translateY(6px);
    box-shadow: none;
    transition: 0.3s ease-out;
  }
}

.btn05 {
  margin-right: auto;
  margin-left: auto;

  @include max_scr($bp_tb) {
    text-align: center;
  }

  a {
    border-radius: 100px;
    width: 100%;
    max-width: 280px;
    background-color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 50px;
    position: relative;
    overflow: hidden;
    border: solid 1px #cccccc;
    box-sizing: border-box;

    &:hover {
      width: 100%;
      height: 100%;
      color: #fff;
      z-index: 1;

      &:after {
        transform: translate(-50%, -50%) scale(100, 100);
        z-index: -1;
      }
    }

    &:after {
      //content: "\f0a9";
      content: "●";
      //font-family: "Font Awesome 6 Free";
      font-weight: 900;
      top: 50%;
      left: 30px;
      transform: translateY(-50%);
      position: absolute;
      color: #cccccc;
      font-size: 12px;
      transform: translate(-50%, -50%) scale(1, 1);
      transition: transform ease-in 0.3s;
    }
  }
}

.btn06 {
  a {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    /*max-width: 280px;*/
    background-color: #00173a;
    color: #fff;
    font-weight: 600;
    box-sizing: border-box;
    padding: 15px 30px;

    &:before {
      content: "";
      background-color: #fff;
      width: 4px;
      height: 60%;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
    }

    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      background-image: url(../images/icon/arrow_right01.svg);
      width: 28px;
      height: 9px;
      background-size: contain;
      transition: all 0.3s;
    }

    &:hover {
      &:after {
        right: 15px;
        transition: all 0.3s;
      }
    }
  }
}

a:hover {
  transition: 0.5s ease-out;

  .btn07 {

    p.p01,
    a {
      background-color: #2293B7CC;
      transition: 0.5s ease-out;
    }
  }
}


.btn07 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  p.p01,
  a {
    transition: 0.5s ease-out;
    position: relative;
    display: block;
    aspect-ratio: 1/1;
    background: #2293B733;
    width: 150px;
    height: 150px;
    border: 0;
    box-sizing: border-box;
    // margin: 1em;
    // padding: 1em 2em;
    box-shadow: inset 0 0 0 2px #666;
    font-family: "Josefin Slab", serif;
    color: #fff;
    font-size: 24px;
    font-weight: 600;

    &::before,
    &::after {
      box-sizing: inherit;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center;
    }

    &.circle {
      border-radius: 100%;
      box-shadow: none;

      &::before,
      &::after {
        border-radius: 100%;
      }
    }

    &.spin {
      width: 150px;
      height: 150px;
      padding: 0;

      &:hover {
        color: #000;
      }

      &::before,
      &::after {
        top: 0;
        left: 0;
      }

      &::before {
        //border: 2px solid transparent; // We're animating border-color again
        border: 2px solid #fff;
      }

      &:hover::before {
        border-top-color: #000; // Show borders
        border-right-color: #000;
        border-bottom-color: #000;
        border-left-color: transparent;

        transition:
          border-top-color 0.15s linear, // Stagger border appearances
          border-right-color 0.15s linear 0.10s,
          border-bottom-color 0.15s linear 0.20s;
      }

      &::after {
        border: 0 solid transparent; // Makes border thinner at the edges? I forgot what I was doing
      }

      &:hover::after {
        border-top: 2px solid #000; // Shows border
        border-left-width: 2px; // Solid edges, invisible borders
        border-right-width: 2px; // Solid edges, invisible borders
        transform: rotate(270deg); // Rotate around circle
        transition:
          transform 0.4s linear 0s,
          border-left-width 0s linear 0.35s; // Solid edge post-rotation
      }
    }


  }
}

/******************************/
/* テキストリンク */
/******************************/

a.tel_link {
  line-height: normal;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  font-family: "Josefin Slab", serif;
  display: flex;
  align-items: center;

  span {
    line-height: 1;
  }

  &::before {
    content: "";
    display: inline-block;
    background-image: url(../images/icon/btn_icon/tel.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    margin-right: 3px;

    @include max_scr($bp_tb) {
      width: 25px;
      height: 25px;
      top: 1px;
      position: relative;

    }
  }
}

a.mail_link {
  line-height: normal;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  font-family: $en_font01;

  &::before {
    content: "\f0e0";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    color: #333;
    font-size: 25px;
    position: relative;
  }
}

.text_link01 {
  a {
    position: relative;
    width: 100%;
    /*max-width: 220px;*/
    padding-bottom: 10px;
    display: inline-block;
    border-bottom: solid 1px #ccc;

    &:hover {
      &:after {
        right: 10px;
        transition: 0.3s ease-out;
      }
    }

    &:after {
      content: "";
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      position: absolute;
      background-image: url(../images/icon/feather-arrow-right.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 26px;
      height: 18px;

      @include max_scr($bp_sp) {
        width: calc(26px * 0.6);
        height: calc(18px * 0.6);
      }
    }

    span {
      font-family: $en_font01;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        border-bottom: solid 2px #666;
        width: 30px;
        height: 1px;
        bottom: -1px;
      }
    }
  }
}

.text_link02 {
  a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding-bottom: 10px;

    &:hover {
      &:after {
        right: 10px;
        transition: 0.3s ease-out;
      }
    }

    &:after {
      content: "";

      background-image: url(../images/icon/feather-arrow-right.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 26px;
      height: 18px;

      @include max_scr($bp_sp) {
        width: calc(26px * 0.6);
        height: calc(18px * 0.6);
      }
    }

    span {
      font-family: $en_font01;
    }
  }
}

/******************************/
/* レイアウト　 */
/******************************/

.ranking_office_ul {
  li {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: dotted 2px #109EB8;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &.non_icon {
      &:before {
        display: none;
      }

      .imgbox {
        width: 100%;
        max-width: 120px;
        aspect-ratio: 3/2;
      }
    }

    &:before {
      content: "";
      width: 30px;
      height: 28px;
      display: inline-block;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 5px;
    }

    &:nth-child(1):before {
      background-image: url(../images/common/ranking/no01.svg);
    }

    &:nth-child(2):before {
      background-image: url(../images/common/ranking/no02.svg);
    }

    .imgbox {
      width: 100%;
      max-width: 64px;
      aspect-ratio: 64/42;
    }

    .ttlbox {
      flex: 1;
      margin-left: 5px;

      h2 {
        font-size: 15px;

      }
    }
  }
}

.limit_text_1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-height: 1.6;
}

.teleco_ul {
  .li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 60px;
    padding-bottom: 60px;
    border-bottom: dotted 3px #9DB6C9;

    @include max_scr($bp_tb) {
      display: block;
    }

    @include max_scr($bp_tb) {
      margin-bottom: 30px;
      padding-bottom: 30px;
    }

    &:last-child {
      margin-bottom: 0px;
      padding-bottom: 0px;
      border-bottom: none;
    }

    .imgbox {
      width: 40%;

      @include max_scr($bp_tb) {
        width: 100%;
      }
    }

    .txtbox {
      flex: 1;

      @include max_scr($bp_tb) {
        margin-top: 25px;
      }
    }

    &:nth-child(odd) {
      .txtbox {
        margin-left: 40px;

        @include max_scr($bp_tb) {
          margin-left: 0;
        }
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      .txtbox {
        margin-right: 40px;

        @include max_scr($bp_tb) {
          margin-right: 25px;
        }
      }
    }
  }
}


.sns_flex01 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0 10px;

  &.wbox {
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    padding: 15px 20px;
    box-sizing: border-box;
    border-radius: 100px;
    line-height: 1;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    max-width: 28px;

    &.hot_beauty_logo02 {
      object-fit: cover;
      width: 100%;
      height: auto;
      aspect-ratio: 93.75/30;
      max-width: 94px;
    }
  }
}

.layout_a {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;

  @include max_scr($bp_tb) {
    display: block;
  }

  .imgbox {
    width: 50%;
    position: relative;

    @include max_scr($bp_tb) {
      width: 100%;
    }
  }

  .txtbox {
    flex: 1;
    margin-right: 40px;
    padding: 2% 0;

    @include max_scr($bp_tb) {
      margin-right: 0;
      margin-top: 20px;
    }
  }
}

.layout_b {
  display: flex;
  flex-wrap: wrap;

  @include max_scr($bp_tb) {
    display: block;
  }

  .imgbox {
    width: 50%;
    position: relative;

    @include max_scr($bp_tb) {
      width: 100%;
    }
  }

  .txtbox {
    flex: 1;
    margin-left: 40px;
    padding: 2% 0;

    @include max_scr($bp_tb) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}

/* layout_c 横並び */
.layout_c {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;

  li {
    .phone_txt {
      font-size: 16px;
      color: #1f9681;

      a {
        color: #1f9681;
      }

      span {
        font-size: 28px;
        font-family: $en_font01;
        margin-left: 5px;
        font-weight: bold;
      }
    }
  }
}

/* layout_d 画像（枠外）+テキスト */
.layout_d {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  margin-right: auto;

  @include max_scr($bp_tb) {
    display: block;
    box-sizing: border-box;
    padding: 0 5%;
  }

  .imgbox {
    width: 47%;

    @include max_scr($bp_tb) {
      width: 100%;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: auto;
      aspect-ratio: 10/5;
    }
  }

  .txtbox {
    flex: 1;
    margin-left: 50px;

    @include max_scr($bp_tb) {
      margin-left: 0;
      margin-top: 25px;
    }

    .box {
      width: 85%;
      margin-right: auto;

      @include max_scr($bp_tb) {
        width: 100%;
        margin: 0;
      }
    }
  }
}

/* layout_e 画像（枠外）+テキスト */
.layout_e {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row-reverse;
  width: 90%;
  margin-left: auto;

  @include max_scr($bp_tb) {
    display: block;
    box-sizing: border-box;
    padding: 0 5%;
  }

  .imgbox {
    width: 47%;

    @include max_scr($bp_tb) {
      width: 100%;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }

  .txtbox {
    flex: 1;
    margin-right: 50px;

    @include max_scr($bp_tb) {
      margin-right: 0;
      margin-top: 25px;
    }

    .box {
      margin-left: auto;
      width: 85%;

      @include max_scr($bp_tb) {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.sub_images_ul {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @include max_scr($bp_sp) {
    display: block;
  }

  &.cul2 {
    gap: 30px 40px;

    li {
      width: calc(100% / 2 - 20px);
      line-height: 1.6;

      @include max_scr($bp_sp) {
        width: 100%;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &.cul4 {
    @include max_scr($bp_sp) {
      display: flex;
    }

    li {
      width: calc(100% / 4 - 15px);
      line-height: 1.6;

      @include max_scr($bp_sp) {
        width: calc(100% / 2 - 20px);
        line-height: 1.6;
      }
    }
  }

  li {
    width: calc(100% / 3 - 15px);

    @include max_scr($bp_sp) {
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    img {
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }
}

.layout_f {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 0 40px;

  @include max_scr($bp_tb) {
    display: block;
  }

  &.cul2 {
    .li {
      width: calc(100% / 2 - 20px);

      @include max_scr($bp_tb) {
        width: 100%;
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &:before {
      content: "";
      display: inline-block;
      width: 1px;
      height: 100%;
      background-color: #9DB6C9;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include max_scr($bp_tb) {
        width: 100%;
        height: 1px;
      }
    }
  }



}

/******************************/
/* #タブ切り替え */
/******************************/
.tab-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 20px auto;
  max-width: 800px;

}

.tab-label {
  background: #fff;
  color: #333;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
  width: calc(80% / 3);
  margin: 0 auto;
  order: -1;
  position: relative;
  z-index: 1;
  cursor: pointer;
  margin-bottom: 10px;
  // flex: 1;

  @include max_scr($bp_tb) {
    font-size: 12px;
    padding: 5px 0.5em 5px;
    border-bottom: solid 2px #75C1D1;
    width: 100%;
    flex: inherit;
    margin-top: 10px;
  }

  span {
    display: block;
    font-size: 16px;

    &:after {
      content: "\f358";
      font-family: "Font Awesome 6 Free";
      font-weight: 200;
      font-size: 20px;
      display: inline-block;
      color: #75C1D1;
      margin-left: 15px;
    }
  }
}

.tab-content {
  width: 100%;
  height: 0;
  overflow: hidden;
  opacity: 0;

  .box {
    margin-bottom: 60px;
    border-bottom: dotted 3px #9DB6C9;
    padding-bottom: 45px;
    margin-bottom: 45px;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

.tab-switch:checked+.tab-label {
  background: transparent !important;
  color: #2293B7 !important;
  border-bottom: solid 2px #75C1D1;
}

.tab-switch:checked+.tab-label+.tab-content {
  height: auto;
  overflow: auto;
  padding: 0px 15px 15px;
  opacity: 1;
  transition: 0.5s opacity;
  //border-top: dotted 3px #1f9681;
  margin-top: 20px;
  // box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.tab-switch {
  display: none !important;
}

.tab-switch:checked+.tab-label {
  background: #fff;
  position: relative;
}

/*******************/
/*　FAQ　開閉なし*/
/*******************/

.faq_box {
  margin-top: 30px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  &:first-child {
    margin-top: 0;
  }

  dt {
    position: relative;
    border-radius: 15px 15px 0 0;
    background-color: #ea6882;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    padding: 20px 50px 20px 4.3em;
    line-height: 1.6;
    display: flex;
    align-items: center;

    @include max_scr($bp_tb) {
      padding: 10px 10px 10px 3.5em;
      font-size: 16px;
    }

    &:before {
      content: "";
      margin-right: 2em;
      display: inline-block;
      background-image: url(../images/icon/faq/faq_q_icon.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 30px;
      height: 30px;
      position: absolute;
      left: 40px;

      @include max_scr($bp_tb) {
        left: 15px;
      }
    }
  }

  dd {
    position: relative;
    border-radius: 0 0 15px 15px;
    padding: 20px 50px 20px 5.5em;
    border: solid 2px #ea6882;
    background-color: #fff;
    line-height: 1.6;

    @include max_scr($bp_tb) {
      padding: 10px 20px 10px 4em;
    }

    &:before {
      content: "";
      margin-right: 2em;
      display: inline-block;
      background-image: url(../images/icon/faq/faq_a_icon.svg);
      background-size: contain;
      width: 30px;
      height: 30px;
      position: absolute;
      left: 40px;

      @include max_scr($bp_tb) {
        left: 15px;
      }
    }
  }
}

/*******************/
/*　FAQ　アコーディオン*/
/*******************/

.faq_contents {
  h2 {
    font-size: 22px;
    margin-bottom: 25px;
  }
}

.faq_contents {
  .accordion_one {
    margin: 0 auto 30px;

    .accordion_header {
      background-color: #fff;
      color: #333;
      font-weight: 600;
      border: solid 1px #13674d;
      border-radius: 6px;
      /*font-size: 26px;*/
      padding: 20px 5% 20px 50px;
      position: relative;
      z-index: +1;
      cursor: pointer;
      transition-duration: 0.2s;

      &:hover {
        opacity: 0.8;
      }

      &:before {
        display: inline-block;
        content: "";
        margin-right: 10px;
        position: absolute;
        top: 31%;
        left: 20px;
        background-image: url(../images/icon/faq/faq_q_icon.svg);
        background-size: contain;
        background-repeat: no-repeat;
        width: 22px;
        height: 22px;
      }

      .i_box {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        right: 1.5%;
        width: 40px;
        height: 40px;
        margin-top: -20px;
        box-sizing: border-box;
        transform-origin: center center;
        transition-duration: 0.2s;

        .one_i {
          display: block;
          width: 18px;
          height: 18px;
          transform: rotate(90deg);
          transform-origin: center center;
          transition-duration: 0.2s;
          position: relative;

          &:before,
          &:after {
            display: flex;
            content: "";
            background-color: #e88356;
            border-radius: 10px;
            width: 18px;
            height: 2px;
            position: absolute;
            top: 7px;
            left: 0;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            transform-origin: center center;
          }

          &:before {
            width: 2px;
            height: 18px;
            top: 0;
            left: 7px;
          }
        }
      }

      &.stay {
        .i_box {
          transform: rotate(0deg);

          .one_i {
            transform: rotate(0deg);

            &:before {
              content: none;
            }
          }
        }

        &.open {
          .i_box {
            transform: rotate(315deg);

            .one_i {
              transform: rotate(45deg);

              &:before {
                content: "";
              }

              &:after {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
              }
            }
          }
        }
      }

      &.open {
        .i_box {
          transform: rotate(-360deg);

          .one_i {
            &:before {
              content: "";
            }

            &:after {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }
}

/**/
.faq_contents .accordion_one .accordion_inner {
  display: none;
  padding: 0px 30px 30px 50px;
  margin-top: 30px;
  /*border-left: 2px solid #b4b4b4;
    border-right: 2px solid #b4b4b4;
    border-bottom: 2px solid #b4b4b4;*/
  box-sizing: border-box;
  line-height: 1.8;
  position: relative;

  &:before {
    display: inline-block;
    content: "";
    margin-right: 10px;
    position: absolute;
    background-image: url(../images/icon/faq/faq_a_icon.svg);
    top: 0;
    left: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
  }
}

.faq_contents .accordion_one .accordion_inner.stay {
  display: block;
}

/* .s_03 .accordion_one .accordion_inner .box_one {
    height: 300px;
  } */

.faq_contents .accordion_one .accordion_inner p.txt_a_ac {
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .faq_contents .accordion_one .accordion_header {
    font-size: 18px;
  }

  .faq_contents .accordion_one .accordion_header .i_box {
    width: 30px;
    height: 30px;
    margin-top: -15px;
  }
}

@media screen and (max-width: 767px) {
  .faq_contents .accordion_one .accordion_header {
    font-size: 14px;
    text-align: left;
    padding: 15px 30px 15px 35px;
    line-height: 1.5;
  }

  .faq_contents .accordion_one .accordion_header:before {
    left: 7px;
  }

  .faq_contents .accordion_one .accordion_inner {
    padding: 0px 30px 15px 50px;
  }
}

/* ---------- */
/* カテゴリーページャー */
/* ---------- */
.pager {
  font-family: "Josefin Slab", serif;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  gap: 10px;
  margin-top: 60px;
  justify-content: flex-start;
  margin-bottom: 20px;

  @include max_scr($bp_sp) {
    margin-bottom: 30px;
  }

  .page-numbers {
    //border-radius: 50%;
    //border: solid 1px #333333;
    // width: 30px;
    // height: 30px;
    padding: 0 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-out;

    &.current {
      //border: solid 1px #333333;
      //background-color: #333333;
      color: #75C1D1;
    }
  }

  .next {
    border-left: solid 1px #333;
    padding-left: 10px;
  }

  .prev {
    border-right: solid 1px #333;
    padding-right: 10px;
  }
}

/*******************/
/*　ページャー　　　　*/
/*******************/
.post-btn {
  font-family: "Josefin Slab", serif;
  font-weight: 600;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  .prev,
  .next {
    a {
      /*border: solid 1px #333;*/
      color: #333;
      // background-color: #333;
      /*border-radius: 50%;*/
      aspect-ratio: 1/1;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }
  }

  .link_btn {
    /*border: solid 1px #333;*/

    //background-color: #333;
    /*border-radius: 50px;*/
    padding: 3px 25px;
    line-height: 30px;
    border-left: solid 1px #333;
    border-right: solid 1px #333;
    line-height: 1.6;

    a {
      color: #333;
      font-size: 20px;
    }
  }
}

/******************************/
/* レイアウト　リスト （UL）*/
/******************************/
.belonging_flex{
  display: flex;
  justify-content: center;
  gap: 20px;
  @include max_scr($bp_tb) {
  font-size: 13px;
  }

  div{
    position: relative;
    &:after{
      content: "/";
      display: inline-block;
      position: absolute;
      right: -15px;
      top: 0;
    }

    &:last-child{
      &:after{
        display: none;
      }
    }
  }
}

.num_flex01 {
  li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 60px;
    border-bottom: solid 1px #ccc;
    padding-bottom: 30px;

    @include max_scr($bp_sp) {
      align-items: inherit;
    }

    .numarea {
      width: 30%;
      height: auto;
      position: relative;
      z-index: 1;
      text-align: center;
      margin: 0 auto;
      aspect-ratio: 1/1;
      clip-path: polygon(0% 0%, 50% 0%, 100% 100%, 50% 100%);
      background-color: #1D1D1D;

      span {
        color: #fff;
        font-size: 40px;
        font-weight: bold;
        font-family: "Poppins", sans-serif;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include max_scr($bp_sp) {
          font-size: 30px;
        }
      }
    }

    .txtarea {
      flex: 1;
      margin-left: 70px;

      @include max_scr($bp_sp) {
        margin-left: 20px;

        h2.ma_b_2em {
          margin-bottom: 15px !important;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      .numarea {
        width: 35%;
        clip-path: polygon(50% 0%, 100% 0%, 50% 100%, 0% 100%);
        background-color: #EDEDE8;
        color: #1D1D1D;

        span {
          color: #1D1D1D;
          font-size: 40px;
          font-weight: bold;
          font-family: "Poppins", sans-serif;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          @include max_scr($bp_sp) {
            font-size: 30px;
          }
        }
      }

      .txtarea {
        flex: 1;
        margin-left: 0;
        margin-right: 70px;

        @include max_scr($bp_sp) {
          margin-right: 20px;

          h2.ma_b_2em {
            margin-bottom: 15px !important;
          }
        }
      }

    }

    &:nth-child(3n) {
      .numarea {
        background-color: #979797;

        span {
          color: #fff;
        }
      }
    }

  }
}


/*Check　リスト*/
.chk_list {
  list-style-type: none;

  &.cul02 {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 20px;

    li {
      width: calc(100% / 2 - 20px);
      margin: 0;
    }
  }

  li {
    position: relative;
    line-height: 1.8;
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 0 15px;
    // border-bottom: solid 1px #185e57;
    // padding-bottom: 10px;
    // margin-bottom: 13px;

    &:first-child {
      margin-top: 0;
    }

    &::before {
      content: "";
      display: inline-block;
      background-image: url(../images/icon/chk_icon01.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
    }
  }
}

.chk_list02 {
  list-style-type: none;

  &.cul02 {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 20px;

    li {
      width: calc(100% / 2 - 20px);
      margin: 0;
    }
  }

  li {
    position: relative;
    line-height: 1.8;
    margin-top: 20px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #185e57;
    padding-bottom: 10px;
    margin-bottom: 13px;

    &:first-child {
      margin-top: 0;
    }

    &::before {
      content: "\f4fc";
      display: inline-block;
      color: #419498;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      margin-right: 10px;
    }
  }
}

.chk_list03 {
  list-style-type: none;
  content: "\2713";

  &.cul02 {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 20px;

    li {
      width: calc(100% / 2 - 20px);
      margin: 0;
    }
  }

  li {
    position: relative;
    line-height: 1.8;
    margin-top: 20px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #185e57;
    padding-bottom: 10px;
    margin-bottom: 13px;

    &:first-child {
      margin-top: 0;
    }

    &::before {
      content: "\2713";
      display: inline-block;
      color: #419498;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      font-weight: 900;
      margin-right: 10px;
    }
  }
}

.doted {
  li {
    margin-top: 10px;
    list-style: disc;
    margin-left: 1.5em;
    line-height: 1.8;

    &:first-child {
      margin-top: 0;
    }
  }
}

.tbl_list01 {
  li {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .tbl_ttl {
      width: 30%;
      background-color: #f3f7f8;
      border: solid 1px #ccc;
      padding: 20px;
      display: flex;
      align-items: center;

      @include max_scr($bp_sp) {
        padding: 15px 10px;
      }
    }

    .tbl_body {
      flex: 1;
      padding: 20px;
      border-top: solid 1px #ccc;
      border-bottom: solid 1px #ccc;
      border-right: solid 1px #ccc;
      background-color: #fff;

      @include max_scr($bp_sp) {
        padding: 15px 10px;
      }
    }
  }
}

.tbl_list02 {
  li {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: solid 1px #ccc;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1.5px;
      height: 3px;
      width: 140px;
      background-color: #ccc;
    }

    .list_ttl {
      width: 140px;
      line-height: 1.8;
      font-weight: bold;
      padding: 10px 0;
    }

    .list_body {
      flex: 1;
      line-height: 1.8;
      padding: 10px 0;
    }
  }
}

.price_list01 {
  li {
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    border: solid 1px #ccc;

    @include max_scr($bp_sp) {
      display: block;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .list_ttl {
      width: 65%;
      font-weight: bold;
      line-height: 1.4;
      background-color: #efd9d9;
      border-right: solid 1px #ccc;
      padding: 20px;
      font-size: 16px;
      font-weight: 500;

      @include max_scr($bp_sp) {
        width: 100%;
        padding: 10px;
        text-align: center;
      }
    }

    .list_body {
      text-align: center;
      flex: 1;
      line-height: 1.4;
      font-weight: bold;
      padding: 20px;
      background-color: #fff;

      .b_text {
        font-size: 20px;
      }

      .s_text {
        font-size: 13px;
        margin-left: 0.5em;
      }
    }
  }
}

/*フローリスト*/
.flow_list {
  max-width: 1000px;
  margin: 0 auto;

  li {
    padding: 30px 50px;
    position: relative;
    border-radius: 10px;
    background-color: #e9ebe0;
    margin-bottom: 70px;

    @include max_scr($bp_sp) {
      padding: 30px 25px;
      margin-bottom: 30px;
    }

    &:last-child {
      margin-bottom: 0;

      &:before {
        display: none;
      }
    }

    &:before {
      border-top: 40px solid #b1b991;
      border-left: 130px solid transparent;
      border-right: 130px solid transparent;
      content: "";
      position: absolute;
      bottom: -40px;
      left: 50%;
      margin-left: -130px;

      @include max_scr($bp_sp) {
        border-top: 30px solid #b1b991;
        bottom: -30px;
        border-left: 70px solid transparent;
        border-right: 70px solid transparent;
        margin-left: -70px;
      }
    }

    .num {
      font-family: $en_font01;
      color: #767a41;
      margin-bottom: 8px;
      display: block;
      font-weight: 600;
      font-size: 16px;
    }

    p {
      line-height: 1.5;
    }

    &.flow_in_flex {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      @include max_scr($bp_tb) {
        display: block;
      }

      .imgbox {
        width: 170px;
        aspect-ratio: 1/1;

        @include max_scr($bp_tb) {
          width: 50%;
          margin: 0 auto;
          text-align: center;
          aspect-ratio: inherit;
        }
      }

      .txtbox {
        flex: 1;
        margin-left: 40px;

        @include max_scr($bp_tb) {
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }
  }
}

.flow_box {
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #9DB6C9;
  padding: 40px 35px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;

  @include max_scr($bp_tb) {
    display: block;
    padding: 25px 5%;
  }

  .num_ttl {
    width: 200px;

    @include max_scr($bp_tb) {
      width: 100%;
    }

    .num {
      font-family: "Josefin Slab", serif;
      font-weight: 600;
      font-size: 25px;
      line-height: 1;
      margin-bottom: 5px;
      color: #75C1D1;
    }

    .b_ttl {
      font-family: "Shippori Mincho", serif;
      font-weight: 500;
      font-size: 22px;
      line-height: 1;
    }

    .s_ttl {
      font-family: "Josefin Slab", serif;
      font-weight: 600;
      font-size: 20px;
    }
  }

  .box_body {
    flex: 1;
  }


}

/******************************/
/* レイアウト　リスト （DL）*/
/******************************/
.dl_tbl01 {
  display: flex;
  flex-wrap: wrap;

  dt {
    width: 200px;
    border-bottom: solid 2px#ccc;
    color: #0D3761;
    font-weight: 500;
    padding: 10px 0;

    @include max_scr($bp_sp) {
      width: 140px;
    }
  }

  dd {
    width: calc(100% - 200px);
    border-bottom: solid 1px#ccc;
    padding: 10px 0;

    @include max_scr($bp_sp) {
      width: calc(100% - 140px);
    }
  }

}

.tbl_list01_dl {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;

  @include max_scr($bp_tb) {
    display: block;
  }

  &:last-child {
    margin-bottom: 0;
  }

  dt {
    width: 120px;
    max-width: 120px;

    //padding: 5px 0;
    @include max_scr($bp_tb) {
      width: 100%;
      max-width: inherit;
      font-weight: 800;
      line-height: 1;
    }

    &.eng {
      font-family: "Josefin Slab", serif;
      font-weight: 600;
      font-size: 20px;

      @include max_scr($bp_tb) {
        font-weight: 800;
      }
    }
  }

  dd {
    width: calc(100% - 120px);

    //padding: 5px 0;
    @include max_scr($bp_tb) {
      width: 100%;
    }

  }
}

/******************************/
/* レイアウト　テーブル （TABLE）*/
/******************************/
.scrool_tbl {
  overflow-x: auto;
  margin-bottom: 30px;
}

.tbl_price {
  width: 100%;
  border-spacing: 0;
  white-space: nowrap;
  border-collapse: collapse;

  @include max_scr($bp_sp) {
    width: 700px;
  }

  .wd40p {
    width: 40%;
  }

  .wd10p {
    width: 10%;
  }

  thead {
    th {
      color: #aa6908;
      background-color: transparent;
      border: none;
      text-align: left;
    }
  }

  th,
  td {
    border: solid 1px #c8c8c8;
    text-align: left;
    padding: 8px 20px;
  }

  th {
    color: #ffffff;
    background-color: #000;
  }
}

/******************************/
/* ギャラリー　スライダー*/
/******************************/
.swiper-container {
  overflow: hidden;
  /*position: relative;*/
}

.gallery-photo {
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.gallery-photo .gallery-slider .swiper-slide p {
  width: 100%;
  // padding-top: 56.25%;
  aspect-ratio: 16/9;
  position: relative;
  background-color: #fff;
}

.gallery-photo .gallery-slider .swiper-slide p span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.gallery-photo .gallery-slider .swiper-slide p span.img_short {
  background-size: cover !important;
}

.gallery-photo .gallery-slider .swiper-slide p span.img_long {
  background-size: auto 100% !important;
}

.gallery-photo .gallery-slider .swiper-slide p img {
  display: block;
  width: auto;
  height: auto;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  object-fit: contain;
  width: 100%;
  transform: translate(-50%, -50%);
  aspect-ratio: 16/9;
  object-fit: cover;
}

body .gallery-photo .gallery-thumbs {
  margin: 30px 0px 30px;
  padding: 0 45px;
  position: relative;
}

.gallery-photo .gallery-thumbs .swiper-slide {
  width: 25%;
  box-sizing: border-box;
  padding: 0 5px;
  cursor: pointer;
}

.gallery-photo .gallery-thumbs .swiper-slide p {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.gallery-photo .gallery-thumbs .swiper-slide p:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
}

.gallery-photo .gallery-thumbs .swiper-slide.swiper-slide-thumb-active p:after {
  content: "";
  opacity: 0;
}

.gallery-photo .gallery-thumbs .swiper-slide p span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.gallery-photo .gallery-thumbs .swiper-button {
  background-color: #333;
  width: 30px;
  height: 100%;
  border: 0;
  overflow: hidden;
  font-size: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  transition: all 0.3s;
}

.gallery-photo .gallery-thumbs .swiper-button:hover {
  opacity: 0.7;
}

.gallery-photo .gallery-thumbs .swiper-button:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.gallery-photo .gallery-thumbs .swiper-button.swiper-prev {
  left: 0;
}

.gallery-photo .gallery-thumbs .swiper-button.swiper-prev:before {
  background: url("../images/common/sl-prev.svg") no-repeat center/12px auto;
}

.gallery-photo .gallery-thumbs .swiper-button.swiper-next {
  right: 0;
}

.gallery-photo .gallery-thumbs .swiper-button.swiper-next:before {
  background: url("../images/common/sl-next.svg") no-repeat center/12px auto;
}

@media screen and (max-width: 767px) {
  .gallery-photo .gallery-thumbs {
    margin: 20px 0 20px;
    padding: 0 35px;
  }

  .gallery-photo .gallery-thumbs .swiper-slide {
    width: 50%;
  }
}

/******************************/
/* 投稿一覧出力　レイアウト*/
/******************************/
/*サムネイル　縦*/
.thum_list {
  /* max-width: 900px;*/
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 35px 40px;

  @include max_scr($bp_sp) {
    display: block;
  }

  &.cul4 {
    @include max_scr($bp_sp) {
      display: flex;
      gap: 35px 20px;
    }

    li {
      width: calc(100% / 4 - 30px);

      @include max_scr($bp_sp) {
        width: calc(100% / 2 - 10px);
      }
    }
  }

  li {
    width: calc(100% / 3 - 30px);

    @include max_scr($bp_sp) {
      width: 100%;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .date {
      margin-bottom: 5px;
      font-size: 13px;
    }

    .price {
      margin-top: 10px;
    }

    .category_c {
      display: flex;
      flex-direction: column;
      margin-top: 15px;

      a {
        width: fit-content;
        display: inline-block;
        margin-bottom: 5px;
        background-color: #75C1D1;
        color: #ffff;
        border-radius: 2px;
        text-align: center;
        font-size: 12px;
        padding: 5px 20px;

      }
    }

    figure {
      overflow: hidden;
      margin-bottom: 10px;
      aspect-ratio: 285/240;

      img {
        object-fit: cover;
        width: 100%;
        aspect-ratio: 285/240;
      }
    }

    &:hover {
      figure {
        img {
          transform: scale(1.2, 1.2);
          transition: 0.5s all;
        }
      }
    }

    .limit_text_2 {
      font-size: $pc-font_size;
    }
  }
}

/*サムネイル　横*/

.thum_list02 {
  li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: dotted 1px $main-color;

    .imgbox {
      width: 25%;

      @include max_scr($bp_tb) {
        width: 40%;
      }
    }

    .txtbox {
      position: relative;
      flex: 1;
      margin-left: 25px;
      padding-right: 50px;

      .date_area {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0 20px;
      }

      .single_link {
        position: relative;

        &:after {
          content: "\f35a";
          font-family: "Font Awesome 6 Free";
          font-size: 25px;
          color: #00B7BD;
          font-weight: 800;
          position: absolute;
          top: 50%;
          right: -30px;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }

        &:hover {
          transition: 0.3s ease-out;

          &:after {
            transition: 0.3s ease-out;
            right: -35px;
          }
        }
      }

      .category_c {
        a {
          background-color: #08A0A5;
          color: #fff;
          font-size: 12px;
          border-radius: 2px;
          display: inline-block;
          font-weight: bold;
          /* max-width: 140px; */
          padding: 3px 10px;
          text-align: center;

          p {
            @include max_scr($bp_tb) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

/*サムネイルなし*/
.non_thum_list {
  li {
    position: relative;
    border-bottom: dotted 1px #ccc;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include max_scr($bp_tb) {
      display: block;
    }

    &:hover {
      background-color: #F8F6F2;
      transition: all 0.25s ease-in-out;
    }

    &:after {
      content: "\f061";
      font-style: normal;
      line-height: 1;
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      font-size: 18px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s;
      color: #333;
    }

    .non_thum_ttl {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .category_c {
      margin: 0 15px;
      display: flex;

      a {
        border: solid 1px #333;
        background-color: #fff;
        color: #333;
        font-size: 12px;
        border-radius: 20px;
        display: block;
        font-weight: bold;
        /*max-width: 140px;*/
        width: 105px;
        padding: 3px 10px;
        text-align: center;

        @include max_scr($bp_sp) {
          width: auto;
        }


      }
    }
  }
}