@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100..700;1,100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;500;600;700;800&display=swap");
.noto-sans {
  font-family: "Noto Sans JP", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.shippori-mincho {
  font-family: "Shippori Mincho", serif;
  font-style: normal;
}

.josefin-slab {
  font-family: "Josefin Slab", serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.poppins {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

/*ディスプレイ初期値設定*/
/*以下、サイト固有の設定*/
/*サイト共通の間隔*/
/*英語用font読み込み指定*/
/*
@font-face {
	font-family: 'General Sans';
	src: url("../font/GeneralSans-Regular.otf") format("otf");
    font-weight: normal;
    font-style: normal;
}
@font-face {
	font-family: 'General Sans';
	src: url("../font/GeneralSans-Semibold.otf") format("otf");
    font-weight: bold;
    font-style: normal;
}
*/
/*
Theme Name: template1.01
Description: template1.01　全ページカスタマイズテーマです。
*/
/******************************/
/* 見出し　 */
/******************************/
.ttl00 {
  font-size: 40px;
  font-family: "Josefin Slab", serif;
  font-weight: 600;
  position: relative;
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .ttl00 {
    font-size: 30px;
  }
}

.ttl00.type01 {
  text-align: left;
}

@media screen and (max-width: 1024px) {
  .ttl00.type01 {
    text-align: center;
  }
}

.ttl00.type01:after {
  content: "／";
  display: block;
  font-size: 24px;
  margin-top: 5px;
}

.ttl00.type02 {
  text-align: center;
}

.ttl00.type02:after {
  content: "／";
  display: block;
  font-size: 24px;
  margin-top: 5px;
}

.ttl01 {
  position: relative;
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .ttl01 {
    text-align: center;
  }
}

.ttl01.non_line:after {
  display: none;
}

.ttl01.type01 .jp_txt {
  font-size: 16px;
  margin-top: 5px;
}

.ttl01.type01 .eng_txt {
  font-family: "Josefin Slab", serif;
  font-weight: 600;
  font-size: 36px;
  display: inline-block;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .ttl01.type01 .eng_txt {
    font-size: 26px;
  }
}

.ttl01.type02 .jp_txt {
  font-size: 40px;
  line-height: 1;
}

.ttl01.type02 .eng_txt {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 18px;
  display: inline-block;
  color: #3c06a0;
  margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
  .ttl01.type02 .eng_txt {
    font-size: 16px;
  }
}

.ttl01.type02:after {
  bottom: -30px;
}

.ttl01:after {
  content: "";
  position: absolute;
  bottom: -20px;
  display: inline-block;
  width: 100%;
  max-width: 40px;
  height: 1px;
  left: 0;
  border-bottom: solid 2px #3c06a0;
}

@media screen and (max-width: 767px) {
  .ttl01:after {
    content: "";
    position: absolute;
    bottom: -15px;
    display: inline-block;
    width: 100%;
    max-width: 40px;
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: solid 2px #3c06a0;
  }
}

.ttl02 {
  position: relative;
  margin-bottom: 60px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .ttl02 {
    margin-bottom: 40px;
  }
}

.ttl02.non_line:after {
  display: none;
}

.ttl02.type01 .jp_txt {
  font-size: 16px;
  margin-top: 5px;
}

.ttl02.type01 .eng_txt {
  font-family: "Josefin Slab", serif;
  font-weight: 600;
  font-size: 50px;
  display: inline-block;
  line-height: 1;
  color: #9DB6C9;
}

@media screen and (max-width: 767px) {
  .ttl02.type01 .eng_txt {
    font-size: 24px;
  }
}

.ttl02.type02 .jp_txt {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
}

.ttl02.type02 .eng_txt {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 34px;
  display: inline-block;
  margin-bottom: 5px;
  color: #3c06a0;
}

@media screen and (max-width: 767px) {
  .ttl02.type02 .eng_txt {
    font-size: 24px;
  }
}

.ttl02.type02:after {
  bottom: -20px;
}

.ttl02:after {
  content: "";
  position: absolute;
  bottom: -20px;
  display: inline-block;
  width: 100%;
  max-width: 40px;
  height: 1px;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: solid 2px #3c06a0;
}

.ttl03 {
  font-size: 22px;
  font-weight: bold;
  position: relative;
  border-left: solid 4px #3c06a0;
  margin-bottom: 20px;
  padding: 0 10px 0;
}

@media screen and (max-width: 767px) {
  .ttl03 {
    font-size: 16px;
    margin-bottom: 15px;
  }
}

.ttl03::before {
  content: "";
  position: absolute;
  width: calc(100%);
  left: -4px;
  bottom: -10px;
  height: 2px;
  border-bottom: dotted 2px #3c06a0;
}

.ttl03.non_border:before {
  display: none;
}

.ttl04 {
  position: relative;
  text-align: center;
  border-bottom: solid 1px #9DB6C9;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.ttl04 .jp_ttl {
  font-size: 15px;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 3px;
}

.ttl04 .jp_ttl02 {
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
}

.ttl04 .eng_ttl {
  font-family: "Josefin Slab", serif;
  font-weight: 600;
  color: #75C1D1;
  font-size: 24px;
}

.ttl05 {
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 22px;
  border-bottom: solid 1px #9DB6C9;
  padding-bottom: 12px;
  margin-bottom: 15px;
}

.ttl06 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0 16px;
}

.ttl06 .en_ttl {
  font-family: "Josefin Slab", serif;
  color: #0D3761;
  font-weight: 600;
  font-size: 22px;
}

.ttl06 .jp_ttl {
  font-weight: 400;
  font-size: 16px;
}

@media screen and (max-width: 1024px) {
  .ttl06 .jp_ttl {
    font-size: 13px;
  }
}

.ttl07 {
  position: relative;
}

.ttl07.en_txt {
  font-family: "Josefin Slab", serif;
  font-size: 22px;
  font-weight: 600;
  color: #218EB1;
  border-bottom: solid 1px #9DB6C9;
  padding-bottom: 5px;
}

.ttl08 {
  position: relative;
  margin-bottom: 40px;
  font-weight: bold;
}

.ttl08:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  height: 2px;
  width: 100px;
  background-color: #3c06a0;
}

.ttl09 {
  position: relative;
}

.ttl09 .sub_ttl {
  display: inline-block;
  box-sizing: border-box;
  border-radius: 100px;
  color: #fff;
  line-height: 1;
  font-size: 14px;
  padding: 5px 15px;
  margin-bottom: 5px;
  background-color: #75C1D1;
}

.ttl09 .sub_ttl.cl02 {
  background-color: #8EA19E;
}

.ttl09 .main_ttl {
  font-weight: 500;
  font-size: 20px;
}

@media screen and (max-width: 1024px) {
  .ttl09 .main_ttl {
    font-size: 16px;
  }
}

.ttl10 {
  border-left: 4px solid #75C1D1;
  padding-left: 10px;
  font-weight: 500;
  line-height: 1.2;
}

.ttl11 {
  color: #0D3761;
  margin-bottom: 20px;
}

.ttl11 .en_ttl {
  font-family: "Josefin Slab", serif;
  font-size: 24px;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .ttl11 .en_ttl {
    font-size: 18px;
  }
}

.ttl11 .en_ttl:after {
  content: "|";
  display: inline-block;
  padding-left: 10px;
}

.ttl11 .jp_ttl {
  font-family: "Shippori Mincho", serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .ttl11 .jp_ttl {
    font-size: 16px;
  }
}

/******************************/
/* ボタン */
/******************************/
@media screen and (max-width: 1024px) {
  .btn01 {
    text-align: center;
  }
}

.btn01 a {
  font-family: "Josefin Slab", serif;
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 280px;
  background-color: #fff;
  border: solid 2px #75C1D1;
  color: #1D1D1D;
  border-radius: 100px;
  font-weight: 600;
  font-size: 16px;
  padding: 14px 35px;
  text-align: center;
  z-index: 1;
  overflow: hidden;
  box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
  .btn01 a {
    font-size: 13px;
    max-width: 220px;
    line-height: 1;
  }
}

.btn01 a:hover {
  opacity: 1;
  color: #fff;
}

.btn01 a:hover:before {
  transform: scale(1, 1);
}

.btn01 a:before {
  background: #75C1D1;
  color: #1D1D1D;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  transform: scale(0, 1);
  transform-origin: left top;
  transition: 0.2s cubic-bezier(0.45, 0, 0.55, 1);
  z-index: -1;
}

.btn02 a {
  display: inline-block;
  width: 200px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0em;
  line-height: 20px;
  border-bottom: 1px solid #1a1515;
  text-align: left;
  padding-bottom: 7px;
  margin: 26px 0;
  position: relative;
  box-sizing: border-box;
}

.btn02 a:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 0;
  right: 100%;
  bottom: 0;
  background: #1a1515;
  height: 1px;
  transition-property: right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.btn02 a i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border: 1px solid #1a1515;
  /* font-family: 'Zen Old Mincho', serif; */
  font-weight: 600;
  font-size: 21px;
  box-sizing: border-box;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  margin-left: -15px;
  transition: all 0.3s;
}

.btn02 a i.txt2 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

.btn02 a:hover {
  opacity: 0.7;
}

.btn02 a:hover:before {
  right: 0;
}

.btn02 a:hover i {
  background-color: #1a1515;
  color: #fff;
}

.btn03 a {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 280px;
  background-color: #ea6882;
  overflow: hidden;
  color: #fff;
  border-radius: 100px;
  font-weight: 500;
  font-size: 15px;
  padding: 14px 35px;
  text-align: left;
  z-index: 1;
  box-sizing: border-box;
}

.btn03 a:before {
  background: #be546a;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  transform: scale(0, 1);
  transform-origin: left top;
  transition: 0.2s cubic-bezier(0.45, 0, 0.55, 1);
  z-index: -1;
}

.btn03 a:after {
  content: "\f061";
  font-style: normal;
  line-height: 1;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  font-size: 18px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  transition: all 0.3s;
  color: #333;
}

.btn03 a:hover {
  color: #fff;
}

.btn03 a:hover:before {
  transform: scale(1, 1);
}

.btn03 a:hover:after {
  right: 8px;
  color: #fff;
}

.btn04 a {
  display: inline-block;
  width: 100%;
  max-width: 280px;
  background-color: #ffa500;
  color: #333;
  border-radius: 6px;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0em;
  line-height: 24px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 14px 0;
  box-shadow: 0 6px 0 #5f3f25;
  position: relative;
  z-index: 1;
  text-decoration: none;
  margin: 0 auto;
  box-sizing: border-box;
}

.btn04 a:after {
  content: "\f0a9";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  font-size: 18px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  transition: all 0.3s;
  color: #333;
  background-color: #ffa500;
  border-radius: 50%;
}

.btn04:hover {
  opacity: 1;
  transform: translateY(6px);
  box-shadow: none;
  transition: 0.3s ease-out;
}

.btn05 {
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 1024px) {
  .btn05 {
    text-align: center;
  }
}

.btn05 a {
  border-radius: 100px;
  width: 100%;
  max-width: 280px;
  background-color: #fff;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  position: relative;
  overflow: hidden;
  border: solid 1px #cccccc;
  box-sizing: border-box;
}

.btn05 a:hover {
  width: 100%;
  height: 100%;
  color: #fff;
  z-index: 1;
}

.btn05 a:hover:after {
  transform: translate(-50%, -50%) scale(100, 100);
  z-index: -1;
}

.btn05 a:after {
  content: "●";
  font-weight: 900;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
  position: absolute;
  color: #cccccc;
  font-size: 12px;
  transform: translate(-50%, -50%) scale(1, 1);
  transition: transform ease-in 0.3s;
}

.btn06 a {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  /*max-width: 280px;*/
  background-color: #00173a;
  color: #fff;
  font-weight: 600;
  box-sizing: border-box;
  padding: 15px 30px;
}

.btn06 a:before {
  content: "";
  background-color: #fff;
  width: 4px;
  height: 60%;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.btn06 a:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background-image: url(../images/icon/arrow_right01.svg);
  width: 28px;
  height: 9px;
  background-size: contain;
  transition: all 0.3s;
}

.btn06 a:hover:after {
  right: 15px;
  transition: all 0.3s;
}

a:hover {
  transition: 0.5s ease-out;
}

a:hover .btn07 p.p01,
a:hover .btn07 a {
  background-color: #2293B7CC;
  transition: 0.5s ease-out;
}

.btn07 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn07 p.p01,
.btn07 a {
  transition: 0.5s ease-out;
  position: relative;
  display: block;
  aspect-ratio: 1/1;
  background: #2293B733;
  width: 150px;
  height: 150px;
  border: 0;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 2px #666;
  font-family: "Josefin Slab", serif;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}

.btn07 p.p01::before, .btn07 p.p01::after,
.btn07 a::before,
.btn07 a::after {
  box-sizing: inherit;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
}

.btn07 p.p01 span,
.btn07 a span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.btn07 p.p01.circle,
.btn07 a.circle {
  border-radius: 100%;
  box-shadow: none;
}

.btn07 p.p01.circle::before, .btn07 p.p01.circle::after,
.btn07 a.circle::before,
.btn07 a.circle::after {
  border-radius: 100%;
}

.btn07 p.p01.spin,
.btn07 a.spin {
  width: 150px;
  height: 150px;
  padding: 0;
}

.btn07 p.p01.spin:hover,
.btn07 a.spin:hover {
  color: #000;
}

.btn07 p.p01.spin::before, .btn07 p.p01.spin::after,
.btn07 a.spin::before,
.btn07 a.spin::after {
  top: 0;
  left: 0;
}

.btn07 p.p01.spin::before,
.btn07 a.spin::before {
  border: 2px solid #fff;
}

.btn07 p.p01.spin:hover::before,
.btn07 a.spin:hover::before {
  border-top-color: #000;
  border-right-color: #000;
  border-bottom-color: #000;
  border-left-color: transparent;
  transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s;
}

.btn07 p.p01.spin::after,
.btn07 a.spin::after {
  border: 0 solid transparent;
}

.btn07 p.p01.spin:hover::after,
.btn07 a.spin:hover::after {
  border-top: 2px solid #000;
  border-left-width: 2px;
  border-right-width: 2px;
  transform: rotate(270deg);
  transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s;
}

/******************************/
/* テキストリンク */
/******************************/
a.tel_link {
  line-height: normal;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  font-family: "Josefin Slab", serif;
  display: flex;
  align-items: center;
}

a.tel_link span {
  line-height: 1;
}

a.tel_link::before {
  content: "";
  display: inline-block;
  background-image: url(../images/icon/btn_icon/tel.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  margin-right: 3px;
}

@media screen and (max-width: 1024px) {
  a.tel_link::before {
    width: 25px;
    height: 25px;
    top: 1px;
    position: relative;
  }
}

a.mail_link {
  line-height: normal;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
}

a.mail_link::before {
  content: "\f0e0";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  color: #333;
  font-size: 25px;
  position: relative;
}

.text_link01 a {
  position: relative;
  width: 100%;
  /*max-width: 220px;*/
  padding-bottom: 10px;
  display: inline-block;
  border-bottom: solid 1px #ccc;
}

.text_link01 a:hover:after {
  right: 10px;
  transition: 0.3s ease-out;
}

.text_link01 a:after {
  content: "";
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  position: absolute;
  background-image: url(../images/icon/feather-arrow-right.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 26px;
  height: 18px;
}

@media screen and (max-width: 767px) {
  .text_link01 a:after {
    width: calc(26px * 0.6);
    height: calc(18px * 0.6);
  }
}

.text_link01 a span {
  font-family: "Poppins", sans-serif;
}

.text_link01 a span:before {
  content: "";
  position: absolute;
  left: 0;
  border-bottom: solid 2px #666;
  width: 30px;
  height: 1px;
  bottom: -1px;
}

.text_link02 a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding-bottom: 10px;
}

.text_link02 a:hover:after {
  right: 10px;
  transition: 0.3s ease-out;
}

.text_link02 a:after {
  content: "";
  background-image: url(../images/icon/feather-arrow-right.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 26px;
  height: 18px;
}

@media screen and (max-width: 767px) {
  .text_link02 a:after {
    width: calc(26px * 0.6);
    height: calc(18px * 0.6);
  }
}

.text_link02 a span {
  font-family: "Poppins", sans-serif;
}

/******************************/
/* レイアウト　 */
/******************************/
.ranking_office_ul li {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: dotted 2px #109EB8;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.ranking_office_ul li.non_icon:before {
  display: none;
}

.ranking_office_ul li.non_icon .imgbox {
  width: 100%;
  max-width: 120px;
  aspect-ratio: 3/2;
}

.ranking_office_ul li:before {
  content: "";
  width: 30px;
  height: 28px;
  display: inline-block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 5px;
}

.ranking_office_ul li:nth-child(1):before {
  background-image: url(../images/common/ranking/no01.svg);
}

.ranking_office_ul li:nth-child(2):before {
  background-image: url(../images/common/ranking/no02.svg);
}

.ranking_office_ul li .imgbox {
  width: 100%;
  max-width: 64px;
  aspect-ratio: 64/42;
}

.ranking_office_ul li .ttlbox {
  flex: 1;
  margin-left: 5px;
}

.ranking_office_ul li .ttlbox h2 {
  font-size: 15px;
}

.limit_text_1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-height: 1.6;
}

.teleco_ul .li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 60px;
  padding-bottom: 60px;
  border-bottom: dotted 3px #9DB6C9;
}

@media screen and (max-width: 1024px) {
  .teleco_ul .li {
    display: block;
  }
}

@media screen and (max-width: 1024px) {
  .teleco_ul .li {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
}

.teleco_ul .li:last-child {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: none;
}

.teleco_ul .li .imgbox {
  width: 40%;
}

@media screen and (max-width: 1024px) {
  .teleco_ul .li .imgbox {
    width: 100%;
  }
}

.teleco_ul .li .txtbox {
  flex: 1;
}

@media screen and (max-width: 1024px) {
  .teleco_ul .li .txtbox {
    margin-top: 25px;
  }
}

.teleco_ul .li:nth-child(odd) .txtbox {
  margin-left: 40px;
}

@media screen and (max-width: 1024px) {
  .teleco_ul .li:nth-child(odd) .txtbox {
    margin-left: 0;
  }
}

.teleco_ul .li:nth-child(even) {
  flex-direction: row-reverse;
}

.teleco_ul .li:nth-child(even) .txtbox {
  margin-right: 40px;
}

@media screen and (max-width: 1024px) {
  .teleco_ul .li:nth-child(even) .txtbox {
    margin-right: 25px;
  }
}

.sns_flex01 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0 10px;
}

.sns_flex01.wbox {
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  padding: 15px 20px;
  box-sizing: border-box;
  border-radius: 100px;
  line-height: 1;
}

.sns_flex01 img {
  object-fit: cover;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  max-width: 28px;
}

.sns_flex01 img.hot_beauty_logo02 {
  object-fit: cover;
  width: 100%;
  height: auto;
  aspect-ratio: 93.75/30;
  max-width: 94px;
}

.layout_a {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

@media screen and (max-width: 1024px) {
  .layout_a {
    display: block;
  }
}

.layout_a .imgbox {
  width: 50%;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .layout_a .imgbox {
    width: 100%;
  }
}

.layout_a .txtbox {
  flex: 1;
  margin-right: 40px;
  padding: 2% 0;
}

@media screen and (max-width: 1024px) {
  .layout_a .txtbox {
    margin-right: 0;
    margin-top: 20px;
  }
}

.layout_b {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
  .layout_b {
    display: block;
  }
}

.layout_b .imgbox {
  width: 50%;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .layout_b .imgbox {
    width: 100%;
  }
}

.layout_b .txtbox {
  flex: 1;
  margin-left: 40px;
  padding: 2% 0;
}

@media screen and (max-width: 1024px) {
  .layout_b .txtbox {
    margin-left: 0;
    margin-top: 20px;
  }
}

/* layout_c 横並び */
.layout_c {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
}

.layout_c li .phone_txt {
  font-size: 16px;
  color: #1f9681;
}

.layout_c li .phone_txt a {
  color: #1f9681;
}

.layout_c li .phone_txt span {
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  margin-left: 5px;
  font-weight: bold;
}

/* layout_d 画像（枠外）+テキスト */
.layout_d {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  margin-right: auto;
}

@media screen and (max-width: 1024px) {
  .layout_d {
    display: block;
    box-sizing: border-box;
    padding: 0 5%;
  }
}

.layout_d .imgbox {
  width: 47%;
}

@media screen and (max-width: 1024px) {
  .layout_d .imgbox {
    width: 100%;
  }
}

.layout_d .imgbox img {
  object-fit: cover;
  width: 100%;
  height: auto;
  aspect-ratio: 10/5;
}

.layout_d .txtbox {
  flex: 1;
  margin-left: 50px;
}

@media screen and (max-width: 1024px) {
  .layout_d .txtbox {
    margin-left: 0;
    margin-top: 25px;
  }
}

.layout_d .txtbox .box {
  width: 85%;
  margin-right: auto;
}

@media screen and (max-width: 1024px) {
  .layout_d .txtbox .box {
    width: 100%;
    margin: 0;
  }
}

/* layout_e 画像（枠外）+テキスト */
.layout_e {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row-reverse;
  width: 90%;
  margin-left: auto;
}

@media screen and (max-width: 1024px) {
  .layout_e {
    display: block;
    box-sizing: border-box;
    padding: 0 5%;
  }
}

.layout_e .imgbox {
  width: 47%;
}

@media screen and (max-width: 1024px) {
  .layout_e .imgbox {
    width: 100%;
  }
}

.layout_e .imgbox img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.layout_e .txtbox {
  flex: 1;
  margin-right: 50px;
}

@media screen and (max-width: 1024px) {
  .layout_e .txtbox {
    margin-right: 0;
    margin-top: 25px;
  }
}

.layout_e .txtbox .box {
  margin-left: auto;
  width: 85%;
}

@media screen and (max-width: 1024px) {
  .layout_e .txtbox .box {
    width: 100%;
    margin: 0;
  }
}

.sub_images_ul {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

@media screen and (max-width: 767px) {
  .sub_images_ul {
    display: block;
  }
}

.sub_images_ul.cul2 {
  gap: 30px 40px;
}

.sub_images_ul.cul2 li {
  width: calc(100% / 2 - 20px);
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .sub_images_ul.cul2 li {
    width: 100%;
    margin-bottom: 20px;
  }
  .sub_images_ul.cul2 li:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .sub_images_ul.cul4 {
    display: flex;
  }
}

.sub_images_ul.cul4 li {
  width: calc(100% / 4 - 15px);
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .sub_images_ul.cul4 li {
    width: calc(100% / 2 - 20px);
    line-height: 1.6;
  }
}

.sub_images_ul li {
  width: calc(100% / 3 - 15px);
}

@media screen and (max-width: 767px) {
  .sub_images_ul li {
    width: 100%;
    margin-bottom: 20px;
  }
  .sub_images_ul li:last-child {
    margin-bottom: 0;
  }
}

.sub_images_ul li img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.layout_f {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 0 40px;
}

@media screen and (max-width: 1024px) {
  .layout_f {
    display: block;
  }
}

.layout_f.cul2 .li {
  width: calc(100% / 2 - 20px);
}

@media screen and (max-width: 1024px) {
  .layout_f.cul2 .li {
    width: 100%;
    margin-bottom: 40px;
  }
  .layout_f.cul2 .li:last-child {
    margin-bottom: 0;
  }
}

.layout_f.cul2:before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 100%;
  background-color: #9DB6C9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1024px) {
  .layout_f.cul2:before {
    width: 100%;
    height: 1px;
  }
}

/******************************/
/* #タブ切り替え */
/******************************/
.tab-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 20px auto;
  max-width: 800px;
}

.tab-label {
  background: #fff;
  color: #333;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
  width: calc(80% / 3);
  margin: 0 auto;
  order: -1;
  position: relative;
  z-index: 1;
  cursor: pointer;
  margin-bottom: 10px;
}

@media screen and (max-width: 1024px) {
  .tab-label {
    font-size: 12px;
    padding: 5px 0.5em 5px;
    border-bottom: solid 2px #75C1D1;
    width: 100%;
    flex: inherit;
    margin-top: 10px;
  }
}

.tab-label span {
  display: block;
  font-size: 16px;
}

.tab-label span:after {
  content: "\f358";
  font-family: "Font Awesome 6 Free";
  font-weight: 200;
  font-size: 20px;
  display: inline-block;
  color: #75C1D1;
  margin-left: 15px;
}

.tab-content {
  width: 100%;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.tab-content .box {
  margin-bottom: 60px;
  border-bottom: dotted 3px #9DB6C9;
  padding-bottom: 45px;
  margin-bottom: 45px;
}

.tab-content .box:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.tab-switch:checked + .tab-label {
  background: transparent !important;
  color: #2293B7 !important;
  border-bottom: solid 2px #75C1D1;
}

.tab-switch:checked + .tab-label + .tab-content {
  height: auto;
  overflow: auto;
  padding: 0px 15px 15px;
  opacity: 1;
  transition: 0.5s opacity;
  margin-top: 20px;
}

.tab-switch {
  display: none !important;
}

.tab-switch:checked + .tab-label {
  background: #fff;
  position: relative;
}

/*******************/
/*　FAQ　開閉なし*/
/*******************/
.faq_box {
  margin-top: 30px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.faq_box:first-child {
  margin-top: 0;
}

.faq_box dt {
  position: relative;
  border-radius: 15px 15px 0 0;
  background-color: #ea6882;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  padding: 20px 50px 20px 4.3em;
  line-height: 1.6;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .faq_box dt {
    padding: 10px 10px 10px 3.5em;
    font-size: 16px;
  }
}

.faq_box dt:before {
  content: "";
  margin-right: 2em;
  display: inline-block;
  background-image: url(../images/icon/faq/faq_q_icon.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 40px;
}

@media screen and (max-width: 1024px) {
  .faq_box dt:before {
    left: 15px;
  }
}

.faq_box dd {
  position: relative;
  border-radius: 0 0 15px 15px;
  padding: 20px 50px 20px 5.5em;
  border: solid 2px #ea6882;
  background-color: #fff;
  line-height: 1.6;
}

@media screen and (max-width: 1024px) {
  .faq_box dd {
    padding: 10px 20px 10px 4em;
  }
}

.faq_box dd:before {
  content: "";
  margin-right: 2em;
  display: inline-block;
  background-image: url(../images/icon/faq/faq_a_icon.svg);
  background-size: contain;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 40px;
}

@media screen and (max-width: 1024px) {
  .faq_box dd:before {
    left: 15px;
  }
}

/*******************/
/*　FAQ　アコーディオン*/
/*******************/
.faq_contents h2 {
  font-size: 22px;
  margin-bottom: 25px;
}

.faq_contents .accordion_one {
  margin: 0 auto 30px;
}

.faq_contents .accordion_one .accordion_header {
  background-color: #fff;
  color: #333;
  font-weight: 600;
  border: solid 1px #13674d;
  border-radius: 6px;
  /*font-size: 26px;*/
  padding: 20px 5% 20px 50px;
  position: relative;
  z-index: +1;
  cursor: pointer;
  transition-duration: 0.2s;
}

.faq_contents .accordion_one .accordion_header:hover {
  opacity: 0.8;
}

.faq_contents .accordion_one .accordion_header:before {
  display: inline-block;
  content: "";
  margin-right: 10px;
  position: absolute;
  top: 31%;
  left: 20px;
  background-image: url(../images/icon/faq/faq_q_icon.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 22px;
  height: 22px;
}

.faq_contents .accordion_one .accordion_header .i_box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 1.5%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  box-sizing: border-box;
  transform-origin: center center;
  transition-duration: 0.2s;
}

.faq_contents .accordion_one .accordion_header .i_box .one_i {
  display: block;
  width: 18px;
  height: 18px;
  transform: rotate(90deg);
  transform-origin: center center;
  transition-duration: 0.2s;
  position: relative;
}

.faq_contents .accordion_one .accordion_header .i_box .one_i:before, .faq_contents .accordion_one .accordion_header .i_box .one_i:after {
  display: flex;
  content: "";
  background-color: #e88356;
  border-radius: 10px;
  width: 18px;
  height: 2px;
  position: absolute;
  top: 7px;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transform-origin: center center;
}

.faq_contents .accordion_one .accordion_header .i_box .one_i:before {
  width: 2px;
  height: 18px;
  top: 0;
  left: 7px;
}

.faq_contents .accordion_one .accordion_header.stay .i_box {
  transform: rotate(0deg);
}

.faq_contents .accordion_one .accordion_header.stay .i_box .one_i {
  transform: rotate(0deg);
}

.faq_contents .accordion_one .accordion_header.stay .i_box .one_i:before {
  content: none;
}

.faq_contents .accordion_one .accordion_header.stay.open .i_box {
  transform: rotate(315deg);
}

.faq_contents .accordion_one .accordion_header.stay.open .i_box .one_i {
  transform: rotate(45deg);
}

.faq_contents .accordion_one .accordion_header.stay.open .i_box .one_i:before {
  content: "";
}

.faq_contents .accordion_one .accordion_header.stay.open .i_box .one_i:after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.faq_contents .accordion_one .accordion_header.open .i_box {
  transform: rotate(-360deg);
}

.faq_contents .accordion_one .accordion_header.open .i_box .one_i:before {
  content: "";
}

.faq_contents .accordion_one .accordion_header.open .i_box .one_i:after {
  transform: rotate(90deg);
}

/**/
.faq_contents .accordion_one .accordion_inner {
  display: none;
  padding: 0px 30px 30px 50px;
  margin-top: 30px;
  /*border-left: 2px solid #b4b4b4;
    border-right: 2px solid #b4b4b4;
    border-bottom: 2px solid #b4b4b4;*/
  box-sizing: border-box;
  line-height: 1.8;
  position: relative;
}

.faq_contents .accordion_one .accordion_inner:before {
  display: inline-block;
  content: "";
  margin-right: 10px;
  position: absolute;
  background-image: url(../images/icon/faq/faq_a_icon.svg);
  top: 0;
  left: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  width: 22px;
  height: 22px;
}

.faq_contents .accordion_one .accordion_inner.stay {
  display: block;
}

/* .s_03 .accordion_one .accordion_inner .box_one {
    height: 300px;
  } */
.faq_contents .accordion_one .accordion_inner p.txt_a_ac {
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .faq_contents .accordion_one .accordion_header {
    font-size: 18px;
  }
  .faq_contents .accordion_one .accordion_header .i_box {
    width: 30px;
    height: 30px;
    margin-top: -15px;
  }
}

@media screen and (max-width: 767px) {
  .faq_contents .accordion_one .accordion_header {
    font-size: 14px;
    text-align: left;
    padding: 15px 30px 15px 35px;
    line-height: 1.5;
  }
  .faq_contents .accordion_one .accordion_header:before {
    left: 7px;
  }
  .faq_contents .accordion_one .accordion_inner {
    padding: 0px 30px 15px 50px;
  }
}

/* ---------- */
/* カテゴリーページャー */
/* ---------- */
.pager {
  font-family: "Josefin Slab", serif;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  gap: 10px;
  margin-top: 60px;
  justify-content: flex-start;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .pager {
    margin-bottom: 30px;
  }
}

.pager .page-numbers {
  padding: 0 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-out;
}

.pager .page-numbers.current {
  color: #75C1D1;
}

.pager .next {
  border-left: solid 1px #333;
  padding-left: 10px;
}

.pager .prev {
  border-right: solid 1px #333;
  padding-right: 10px;
}

/*******************/
/*　ページャー　　　　*/
/*******************/
.post-btn {
  font-family: "Josefin Slab", serif;
  font-weight: 600;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.post-btn .prev a,
.post-btn .next a {
  /*border: solid 1px #333;*/
  color: #333;
  /*border-radius: 50%;*/
  aspect-ratio: 1/1;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.post-btn .link_btn {
  /*border: solid 1px #333;*/
  /*border-radius: 50px;*/
  padding: 3px 25px;
  line-height: 30px;
  border-left: solid 1px #333;
  border-right: solid 1px #333;
  line-height: 1.6;
}

.post-btn .link_btn a {
  color: #333;
  font-size: 20px;
}

/******************************/
/* レイアウト　リスト （UL）*/
/******************************/
.belonging_flex {
  display: flex;
  justify-content: center;
  gap: 20px;
}

@media screen and (max-width: 1024px) {
  .belonging_flex {
    font-size: 13px;
  }
}

.belonging_flex div {
  position: relative;
}

.belonging_flex div:after {
  content: "/";
  display: inline-block;
  position: absolute;
  right: -15px;
  top: 0;
}

.belonging_flex div:last-child:after {
  display: none;
}

.num_flex01 li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 60px;
  border-bottom: solid 1px #ccc;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .num_flex01 li {
    align-items: inherit;
  }
}

.num_flex01 li .numarea {
  width: 30%;
  height: auto;
  position: relative;
  z-index: 1;
  text-align: center;
  margin: 0 auto;
  aspect-ratio: 1/1;
  clip-path: polygon(0% 0%, 50% 0%, 100% 100%, 50% 100%);
  background-color: #1D1D1D;
}

.num_flex01 li .numarea span {
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 767px) {
  .num_flex01 li .numarea span {
    font-size: 30px;
  }
}

.num_flex01 li .txtarea {
  flex: 1;
  margin-left: 70px;
}

@media screen and (max-width: 767px) {
  .num_flex01 li .txtarea {
    margin-left: 20px;
  }
  .num_flex01 li .txtarea h2.ma_b_2em {
    margin-bottom: 15px !important;
  }
}

.num_flex01 li:last-child {
  margin-bottom: 0;
}

.num_flex01 li:nth-child(even) {
  flex-direction: row-reverse;
}

.num_flex01 li:nth-child(even) .numarea {
  width: 35%;
  clip-path: polygon(50% 0%, 100% 0%, 50% 100%, 0% 100%);
  background-color: #EDEDE8;
  color: #1D1D1D;
}

.num_flex01 li:nth-child(even) .numarea span {
  color: #1D1D1D;
  font-size: 40px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 767px) {
  .num_flex01 li:nth-child(even) .numarea span {
    font-size: 30px;
  }
}

.num_flex01 li:nth-child(even) .txtarea {
  flex: 1;
  margin-left: 0;
  margin-right: 70px;
}

@media screen and (max-width: 767px) {
  .num_flex01 li:nth-child(even) .txtarea {
    margin-right: 20px;
  }
  .num_flex01 li:nth-child(even) .txtarea h2.ma_b_2em {
    margin-bottom: 15px !important;
  }
}

.num_flex01 li:nth-child(3n) .numarea {
  background-color: #979797;
}

.num_flex01 li:nth-child(3n) .numarea span {
  color: #fff;
}

/*Check　リスト*/
.chk_list {
  list-style-type: none;
}

.chk_list.cul02 {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 20px;
}

.chk_list.cul02 li {
  width: calc(100% / 2 - 20px);
  margin: 0;
}

.chk_list li {
  position: relative;
  line-height: 1.8;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 0 15px;
}

.chk_list li:first-child {
  margin-top: 0;
}

.chk_list li::before {
  content: "";
  display: inline-block;
  background-image: url(../images/icon/chk_icon01.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.chk_list02 {
  list-style-type: none;
}

.chk_list02.cul02 {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 20px;
}

.chk_list02.cul02 li {
  width: calc(100% / 2 - 20px);
  margin: 0;
}

.chk_list02 li {
  position: relative;
  line-height: 1.8;
  margin-top: 20px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #185e57;
  padding-bottom: 10px;
  margin-bottom: 13px;
}

.chk_list02 li:first-child {
  margin-top: 0;
}

.chk_list02 li::before {
  content: "\f4fc";
  display: inline-block;
  color: #419498;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  margin-right: 10px;
}

.chk_list03 {
  list-style-type: none;
  content: "\2713";
}

.chk_list03.cul02 {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 20px;
}

.chk_list03.cul02 li {
  width: calc(100% / 2 - 20px);
  margin: 0;
}

.chk_list03 li {
  position: relative;
  line-height: 1.8;
  margin-top: 20px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #185e57;
  padding-bottom: 10px;
  margin-bottom: 13px;
}

.chk_list03 li:first-child {
  margin-top: 0;
}

.chk_list03 li::before {
  content: "\2713";
  display: inline-block;
  color: #419498;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  font-weight: 900;
  margin-right: 10px;
}

.doted li {
  margin-top: 10px;
  list-style: disc;
  margin-left: 1.5em;
  line-height: 1.8;
}

.doted li:first-child {
  margin-top: 0;
}

.tbl_list01 li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.tbl_list01 li:last-child {
  margin-bottom: 0;
}

.tbl_list01 li .tbl_ttl {
  width: 30%;
  background-color: #f3f7f8;
  border: solid 1px #ccc;
  padding: 20px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .tbl_list01 li .tbl_ttl {
    padding: 15px 10px;
  }
}

.tbl_list01 li .tbl_body {
  flex: 1;
  padding: 20px;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  border-right: solid 1px #ccc;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .tbl_list01 li .tbl_body {
    padding: 15px 10px;
  }
}

.tbl_list02 li {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: solid 1px #ccc;
}

.tbl_list02 li:last-child {
  margin-bottom: 0;
}

.tbl_list02 li:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1.5px;
  height: 3px;
  width: 140px;
  background-color: #ccc;
}

.tbl_list02 li .list_ttl {
  width: 140px;
  line-height: 1.8;
  font-weight: bold;
  padding: 10px 0;
}

.tbl_list02 li .list_body {
  flex: 1;
  line-height: 1.8;
  padding: 10px 0;
}

.price_list01 li {
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  border: solid 1px #ccc;
}

@media screen and (max-width: 767px) {
  .price_list01 li {
    display: block;
  }
}

.price_list01 li:last-child {
  margin-bottom: 0;
}

.price_list01 li .list_ttl {
  width: 65%;
  font-weight: bold;
  line-height: 1.4;
  background-color: #efd9d9;
  border-right: solid 1px #ccc;
  padding: 20px;
  font-size: 16px;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  .price_list01 li .list_ttl {
    width: 100%;
    padding: 10px;
    text-align: center;
  }
}

.price_list01 li .list_body {
  text-align: center;
  flex: 1;
  line-height: 1.4;
  font-weight: bold;
  padding: 20px;
  background-color: #fff;
}

.price_list01 li .list_body .b_text {
  font-size: 20px;
}

.price_list01 li .list_body .s_text {
  font-size: 13px;
  margin-left: 0.5em;
}

/*フローリスト*/
.flow_list {
  max-width: 1000px;
  margin: 0 auto;
}

.flow_list li {
  padding: 30px 50px;
  position: relative;
  border-radius: 10px;
  background-color: #e9ebe0;
  margin-bottom: 70px;
}

@media screen and (max-width: 767px) {
  .flow_list li {
    padding: 30px 25px;
    margin-bottom: 30px;
  }
}

.flow_list li:last-child {
  margin-bottom: 0;
}

.flow_list li:last-child:before {
  display: none;
}

.flow_list li:before {
  border-top: 40px solid #b1b991;
  border-left: 130px solid transparent;
  border-right: 130px solid transparent;
  content: "";
  position: absolute;
  bottom: -40px;
  left: 50%;
  margin-left: -130px;
}

@media screen and (max-width: 767px) {
  .flow_list li:before {
    border-top: 30px solid #b1b991;
    bottom: -30px;
    border-left: 70px solid transparent;
    border-right: 70px solid transparent;
    margin-left: -70px;
  }
}

.flow_list li .num {
  font-family: "Poppins", sans-serif;
  color: #767a41;
  margin-bottom: 8px;
  display: block;
  font-weight: 600;
  font-size: 16px;
}

.flow_list li p {
  line-height: 1.5;
}

.flow_list li.flow_in_flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .flow_list li.flow_in_flex {
    display: block;
  }
}

.flow_list li.flow_in_flex .imgbox {
  width: 170px;
  aspect-ratio: 1/1;
}

@media screen and (max-width: 1024px) {
  .flow_list li.flow_in_flex .imgbox {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    aspect-ratio: inherit;
  }
}

.flow_list li.flow_in_flex .txtbox {
  flex: 1;
  margin-left: 40px;
}

@media screen and (max-width: 1024px) {
  .flow_list li.flow_in_flex .txtbox {
    margin-left: 0;
    margin-top: 20px;
  }
}

.flow_box {
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #9DB6C9;
  padding: 40px 35px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
  .flow_box {
    display: block;
    padding: 25px 5%;
  }
}

.flow_box .num_ttl {
  width: 200px;
}

@media screen and (max-width: 1024px) {
  .flow_box .num_ttl {
    width: 100%;
  }
}

.flow_box .num_ttl .num {
  font-family: "Josefin Slab", serif;
  font-weight: 600;
  font-size: 25px;
  line-height: 1;
  margin-bottom: 5px;
  color: #75C1D1;
}

.flow_box .num_ttl .b_ttl {
  font-family: "Shippori Mincho", serif;
  font-weight: 500;
  font-size: 22px;
  line-height: 1;
}

.flow_box .num_ttl .s_ttl {
  font-family: "Josefin Slab", serif;
  font-weight: 600;
  font-size: 20px;
}

.flow_box .box_body {
  flex: 1;
}

/******************************/
/* レイアウト　リスト （DL）*/
/******************************/
.dl_tbl01 {
  display: flex;
  flex-wrap: wrap;
}

.dl_tbl01 dt {
  width: 200px;
  border-bottom: solid 2px #ccc;
  color: #0D3761;
  font-weight: 500;
  padding: 10px 0;
}

@media screen and (max-width: 767px) {
  .dl_tbl01 dt {
    width: 140px;
  }
}

.dl_tbl01 dd {
  width: calc(100% - 200px);
  border-bottom: solid 1px #ccc;
  padding: 10px 0;
}

@media screen and (max-width: 767px) {
  .dl_tbl01 dd {
    width: calc(100% - 140px);
  }
}

.tbl_list01_dl {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

@media screen and (max-width: 1024px) {
  .tbl_list01_dl {
    display: block;
  }
}

.tbl_list01_dl:last-child {
  margin-bottom: 0;
}

.tbl_list01_dl dt {
  width: 120px;
  max-width: 120px;
}

@media screen and (max-width: 1024px) {
  .tbl_list01_dl dt {
    width: 100%;
    max-width: inherit;
    font-weight: 800;
    line-height: 1;
  }
}

.tbl_list01_dl dt.eng {
  font-family: "Josefin Slab", serif;
  font-weight: 600;
  font-size: 20px;
}

@media screen and (max-width: 1024px) {
  .tbl_list01_dl dt.eng {
    font-weight: 800;
  }
}

.tbl_list01_dl dd {
  width: calc(100% - 120px);
}

@media screen and (max-width: 1024px) {
  .tbl_list01_dl dd {
    width: 100%;
  }
}

/******************************/
/* レイアウト　テーブル （TABLE）*/
/******************************/
.scrool_tbl {
  overflow-x: auto;
  margin-bottom: 30px;
}

.tbl_price {
  width: 100%;
  border-spacing: 0;
  white-space: nowrap;
  border-collapse: collapse;
}

@media screen and (max-width: 767px) {
  .tbl_price {
    width: 700px;
  }
}

.tbl_price .wd40p {
  width: 40%;
}

.tbl_price .wd10p {
  width: 10%;
}

.tbl_price thead th {
  color: #aa6908;
  background-color: transparent;
  border: none;
  text-align: left;
}

.tbl_price th,
.tbl_price td {
  border: solid 1px #c8c8c8;
  text-align: left;
  padding: 8px 20px;
}

.tbl_price th {
  color: #ffffff;
  background-color: #000;
}

/******************************/
/* ギャラリー　スライダー*/
/******************************/
.swiper-container {
  overflow: hidden;
  /*position: relative;*/
}

.gallery-photo {
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.gallery-photo .gallery-slider .swiper-slide p {
  width: 100%;
  aspect-ratio: 16/9;
  position: relative;
  background-color: #fff;
}

.gallery-photo .gallery-slider .swiper-slide p span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.gallery-photo .gallery-slider .swiper-slide p span.img_short {
  background-size: cover !important;
}

.gallery-photo .gallery-slider .swiper-slide p span.img_long {
  background-size: auto 100% !important;
}

.gallery-photo .gallery-slider .swiper-slide p img {
  display: block;
  width: auto;
  height: auto;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  object-fit: contain;
  width: 100%;
  transform: translate(-50%, -50%);
  aspect-ratio: 16/9;
  object-fit: cover;
}

body .gallery-photo .gallery-thumbs {
  margin: 30px 0px 30px;
  padding: 0 45px;
  position: relative;
}

.gallery-photo .gallery-thumbs .swiper-slide {
  width: 25%;
  box-sizing: border-box;
  padding: 0 5px;
  cursor: pointer;
}

.gallery-photo .gallery-thumbs .swiper-slide p {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.gallery-photo .gallery-thumbs .swiper-slide p:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
}

.gallery-photo .gallery-thumbs .swiper-slide.swiper-slide-thumb-active p:after {
  content: "";
  opacity: 0;
}

.gallery-photo .gallery-thumbs .swiper-slide p span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.gallery-photo .gallery-thumbs .swiper-button {
  background-color: #333;
  width: 30px;
  height: 100%;
  border: 0;
  overflow: hidden;
  font-size: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  transition: all 0.3s;
}

.gallery-photo .gallery-thumbs .swiper-button:hover {
  opacity: 0.7;
}

.gallery-photo .gallery-thumbs .swiper-button:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.gallery-photo .gallery-thumbs .swiper-button.swiper-prev {
  left: 0;
}

.gallery-photo .gallery-thumbs .swiper-button.swiper-prev:before {
  background: url("../images/common/sl-prev.svg") no-repeat center/12px auto;
}

.gallery-photo .gallery-thumbs .swiper-button.swiper-next {
  right: 0;
}

.gallery-photo .gallery-thumbs .swiper-button.swiper-next:before {
  background: url("../images/common/sl-next.svg") no-repeat center/12px auto;
}

@media screen and (max-width: 767px) {
  .gallery-photo .gallery-thumbs {
    margin: 20px 0 20px;
    padding: 0 35px;
  }
  .gallery-photo .gallery-thumbs .swiper-slide {
    width: 50%;
  }
}

/******************************/
/* 投稿一覧出力　レイアウト*/
/******************************/
/*サムネイル　縦*/
.thum_list {
  /* max-width: 900px;*/
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 35px 40px;
}

@media screen and (max-width: 767px) {
  .thum_list {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .thum_list.cul4 {
    display: flex;
    gap: 35px 20px;
  }
}

.thum_list.cul4 li {
  width: calc(100% / 4 - 30px);
}

@media screen and (max-width: 767px) {
  .thum_list.cul4 li {
    width: calc(100% / 2 - 10px);
  }
}

.thum_list li {
  width: calc(100% / 3 - 30px);
}

@media screen and (max-width: 767px) {
  .thum_list li {
    width: 100%;
    margin-bottom: 40px;
  }
  .thum_list li:last-child {
    margin-bottom: 0;
  }
}

.thum_list li .date {
  margin-bottom: 5px;
  font-size: 13px;
}

.thum_list li .price {
  margin-top: 10px;
}

.thum_list li .category_c {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.thum_list li .category_c a {
  width: fit-content;
  display: inline-block;
  margin-bottom: 5px;
  background-color: #75C1D1;
  color: #ffff;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  padding: 5px 20px;
}

.thum_list li figure {
  overflow: hidden;
  margin-bottom: 10px;
  aspect-ratio: 285/240;
}

.thum_list li figure img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 285/240;
}

.thum_list li:hover figure img {
  transform: scale(1.2, 1.2);
  transition: 0.5s all;
}

.thum_list li .limit_text_2 {
  font-size: 16px;
}

/*サムネイル　横*/
.thum_list02 li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: dotted 1px #3c06a0;
}

.thum_list02 li .imgbox {
  width: 25%;
}

@media screen and (max-width: 1024px) {
  .thum_list02 li .imgbox {
    width: 40%;
  }
}

.thum_list02 li .txtbox {
  position: relative;
  flex: 1;
  margin-left: 25px;
  padding-right: 50px;
}

.thum_list02 li .txtbox .date_area {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 20px;
}

.thum_list02 li .txtbox .single_link {
  position: relative;
}

.thum_list02 li .txtbox .single_link:after {
  content: "\f35a";
  font-family: "Font Awesome 6 Free";
  font-size: 25px;
  color: #00B7BD;
  font-weight: 800;
  position: absolute;
  top: 50%;
  right: -30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.thum_list02 li .txtbox .single_link:hover {
  transition: 0.3s ease-out;
}

.thum_list02 li .txtbox .single_link:hover:after {
  transition: 0.3s ease-out;
  right: -35px;
}

.thum_list02 li .txtbox .category_c a {
  background-color: #08A0A5;
  color: #fff;
  font-size: 12px;
  border-radius: 2px;
  display: inline-block;
  font-weight: bold;
  /* max-width: 140px; */
  padding: 3px 10px;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .thum_list02 li .txtbox .category_c a p {
    font-size: 12px;
  }
}

/*サムネイルなし*/
.non_thum_list li {
  position: relative;
  border-bottom: dotted 1px #ccc;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .non_thum_list li {
    display: block;
  }
}

.non_thum_list li:hover {
  background-color: #F8F6F2;
  transition: all 0.25s ease-in-out;
}

.non_thum_list li:after {
  content: "\f061";
  font-style: normal;
  line-height: 1;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
  color: #333;
}

.non_thum_list li .non_thum_ttl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.non_thum_list li .category_c {
  margin: 0 15px;
  display: flex;
}

.non_thum_list li .category_c a {
  border: solid 1px #333;
  background-color: #fff;
  color: #333;
  font-size: 12px;
  border-radius: 20px;
  display: block;
  font-weight: bold;
  /*max-width: 140px;*/
  width: 105px;
  padding: 3px 10px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .non_thum_list li .category_c a {
    width: auto;
  }
}
